.imageRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.choices {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius);
  background: var(--neutral-background);
}

.gamePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.userArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.playBoard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
  height: 100%;
}
