.configPanel{
 justify-content: flex-start;
}

.configFields{
  justify-content: flex-start;
  margin-bottom: 1vh;
  padding-right: 1vw;
  padding-left: 1vw;
}

.selectWrapper{
  width: 60%;
}



.gameModeButtons{
  margin: 1vh;
  justify-content: space-between;
  width: 100%;
}
