.invitationLinkField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--neutral-background);
  border-radius: var(--border-radius);
}

@-webkit-keyframes wiggle {
  0% {
    transform: scale(2) rotate(0deg);
  }
  25% {
    transform: scale(2) rotate(2deg);
  }
  50% {
    transform: scale(2) rotate(0deg);
  }
  75% {
    transform: scale(2) rotate(-2deg);
  }
  100% {
    transform: scale(2) rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: scale(2) rotate(0deg);
  }
  25% {
    transform: scale(2) rotate(2deg);
  }
  50% {
    transform: scale(2) rotate(0deg);
  }
  75% {
    transform: scale(2) rotate(-2deg);
  }
  100% {
    transform: scale(2) rotate(0deg);
  }
}
