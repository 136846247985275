.resultsDisplay {
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  padding: 0;
  overflow:initial;
}

.resultsList {
  height: 100%;
  width: 30vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  padding-left: 2vw;
  padding-right: 2vw;
  margin-top: 0;
  border-radius: var(--border-radius);
  border: white;
  border-width: thick;
  backdrop-filter: blur(10px);
  background-size: 150% 150%;
  overflow: scroll;
  background-color: var(--light-neutral-background);
}

.allResults {
  display: flex;
  flex-direction: row;
  height: 60vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 1vw;
  margin-right: 1vw;
  align-items: stretch;
  
}

.tsarResultArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.playerResultArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pointMessage {
  color: black;
}

.score {
  color: rgba(255, 230, 0, 0.952);
  transition: ease-out 1.2s;
}

.losingPoints {
  color: red;
  transform: scale(1.4) rotate(15);
  transition: all 0s;
}

.gainingPoints {
  color: rgb(51, 255, 0);
  transform: scale(1.4) rotate(15);
  transition: all 0s;
}

.myResultsContainer {
  width: 90%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.myResults{
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.reviewImage{
  width: 4.5vw;
  height: undefined;
  aspect-ratio: 1;
  margin-top: 0.5vh;
  border-radius: var(--border-radius);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 5;
  margin:1vh;
}

.tsarImages{
  padding: 0;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.bigResultCueText{
  font-size: 1rem;
  font-weight: bold;
  margin: .3rem;
  word-break: break-word;
}


.smallResultCueText{
  font-size: .75rem;
  font-weight: bold;
  margin: .3rem;
  word-break: break-word;
}
