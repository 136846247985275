.timer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20vw;
  height: 70vh;
  background-color: rgba(255, 255, 255, 0.349);
  border-radius: var(--border-radius);
}

.roundCounter {
  font-size: 24px;
}

.timeCounter {
  justify-self: flex-center;
  font-size: 70px;
  display: inline-block; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1, 2); /* W3C */
}
