.choiceBall {
  height: 3vw;
  width: 3vw;
  border-radius: 100vw;
  z-index: 10;
}

/* @media (pointer: fine) {.choiceBall:hover {
  transform: scale(1.3);
}
} */

.yellowBall {
  box-shadow: 0px 0px 1rem 0.5rem white;
}

.blueBall {
  box-shadow: 0px 0px 1rem 0.5rem var(--neutral-background);
}

.redBall {
  box-shadow: 0px 0px 1rem 0.5rem var(--neutral-background);
}

.greenBall {
  box-shadow: 0px 0px 1rem 0.5rem white;
}

.orangeBall {
  box-shadow: 0px 0px 1rem 0.5rem var(--neutral-background);
}

.choiceWidget {
  display: flex;
  max-width: 10vw;
  height: 28vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  border-radius: var(--border-radius);
  padding-bottom: 1vh;
  background-size: 150% 150%;
  -webkit-animation: backgroundGradient 5s ease-in-out infinite;
  animation: backgroundGradient 5s ease-in-out infinite;
}

.red {
  box-shadow: 0 0 10px var(--red);
  background-color: var(--transparent-red);
}

.green {
  box-shadow: 0 0 10px var(--green);
  background-color: var(--transparent-green);
}

.blue {
  box-shadow: 0 0 10px var(--blue);
  background-color: var(--transparent-blue);
}

.yellow {
  box-shadow: 0 0 10px var(--yellow);
  background-color: var(--transparent-yellow);
}

.orange {
  box-shadow: 0 0 10px var(--orange);
  background-color: var(--transparent-orange);
}

.imageWrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choiceImage {
  position: absolute;
  width: 9vw;
  height: undefined;
  aspect-ratio: 1;
  margin-top: 0.5vh;
  border-radius: var(--border-radius);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease-out;
  z-index: 11;
}

.choiceTextContainer {
 
  position: absolute;
  width: 9vw;
  height: undefined;
  aspect-ratio: 1;
  margin-top: 0.5vh;
  border-radius: var(--border-radius);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 5;
  
}

.choiceTextContaineryellow {
  background-color: var(--dark-yellow);
}

.choiceTextContainerblue {
  background-color: var(--dark-blue);
}

.choiceTextContainerred {
  background-color: var(--dark-red);
}

.choiceTextContainergreen {
  background-color: var(--dark-green);
}

.choiceTextContainerorange {
  background-color: var(--dark-orange);
}

.bigChoiceText{
  word-break: break-word;
  font-size: 1.3rem;
  font-weight: bold;  margin: .3rem;
}

.smallChoiceText{
  word-break: break-word;
  font-size: 0.8rem;
  font-weight: bold;  margin: .3rem;
}

.placeHolder {
  width: 9vw;
  height: 5vh;
  margin-left: 0.5vh;
  margin-right: 0.5vh;
  margin-top: 0.5vh;
}

.choiceImage:hover {
  -webkit-animation: wiggle 0.2s infinite;
  animation: wiggle 0.2s infinite;
}

.enlarged {
  width: 20vw;
  margin-top: -6vh;
  height: undefined;
  aspect-ratio: 1;
  border-radius: var(--border-radius);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  position: absolute;
  float: right;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease-out;
  z-index: 12;
}

/* .choiceImage.enlarged:hover {
  transform: scale(2);
} */

@-webkit-keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
