/* .choiceNests {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
} */

.spacedBall {
  height: 3vw;
  width: 3vw;
  border-radius: 100vw;
  margin-left: 2vw;
  margin-right: 2vw;
  border-radius: 100%;
}


.ratherScale {
  width: 90%;
  height: 4vh;
  align-items: center;
  justify-content: space-around;
}

.sequenceBarContainer {
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.349);
  align-items: center;
  border-radius: var(--border-radius);
  padding: 5px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.sequenceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fluentSizeTransition {
  transition: all .5s ease-out ;
}

.shortSequence {
  
  display: flex;
  width: 40%;
  height: 90%;
  flex-direction: row;
  
}

.dropNow {
  background-color: var(--highlighted);
}

.longSequence {

  display: flex;
  width: 93%;
  height: 90%;
  flex-direction: row;
  
  
}

