body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.unselectableText {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

:root {
  --gradient-colour-one: rgb(126, 24, 92);
  --gradient-colour-two: #ff8181;

  /* --gradient-colour-one: rgb(136, 119, 130);
  --gradient-colour-two: rgb(241, 192, 192); */
  --very-dark-neutral-background: rgba(77, 77, 77, 0.541);
  --dark-neutral-background: #adadad59;
  --neutral-background: #ffffff59;
  --light-neutral-background: #ffffffa1;
  --theme: #e2e2e2d8;
  --border-radius: 15px;
  --yellow: #fff355;
  --blue: #608ad6;
  --red: #e75151;
  --green: #92dc67;
  --orange: #e17d47;
  --yellowBall: yellow;
  --blueBall: rgb(60, 119, 247);
  --redBall: rgb(243, 50, 50);
  --greenBall: rgb(63, 197, 22);
  --orangeBall: rgb(236, 111, 43);
  --transparent-yellow: rgba(255, 244, 85, 0.712);
  --transparent-blue: rgba(96, 137, 214, 0.658);
  --transparent-red: rgba(231, 81, 81, 0.664);
  --transparent-green: rgba(124, 202, 78, 0.671);
  --transparent-orange: rgba(225, 125, 71, 0.658);
  --dark-yellow: rgb(209, 201, 89);
  --dark-blue: rgb(74, 109, 173);
  --dark-red: rgb(189, 62, 62);
  --dark-green: rgb(99, 160, 64);
  --dark-orange: rgb(199, 110, 61);
  --light-yellow: rgb(255, 247, 134);
  --light-blue: rgb(128, 167, 240);
  --light-red: rgb(253, 121, 121);
  --light-green: rgb(189, 253, 152);
  --light-orange: rgb(250, 158, 109);
  --female: rgba(255, 93, 255, 1);
  --male: rgba(3, 207, 252, 1);
  --grey: "#8c8c8c";
  --highlighted: #ec7777;
  --dark-highlighted: rgba(246, 199, 199, 0.6745);
  --very-dark-highlighted: rgba(218, 218, 218, 0.349);
}

.highlightedText {
  color: var(--highlighted);
}

.configBorder {
  border: var(--dark-neutral-background);
  border-radius: var(--border-radius);
  border-style: groove;
}

.raise-on-hover {
  box-shadow: 1.6px 1.2px 13.1px -9px rgba(0, 0, 0, 0.04),
    4.1px 3px 23.2px -9px rgba(0, 0, 0, 0.058), 8.3px 6.2px 32.4px -9px rgba(0, 0, 0, 0.072),
    17.2px 12.8px 45.6px -9px rgba(0, 0, 0, 0.09), 47px 35px 88px -9px rgba(0, 0, 0, 0.13);
  transition: 1s ease-in-out;
}

.gradient-background {
  background: linear-gradient(45deg, rgb(255, 129, 129), rgb(126, 24, 92));
  background-size: 150% 150%;
  -webkit-animation: backgroundGradient 5s ease-in-out infinite;
  animation: backgroundGradient 5s ease-in-out infinite;
}

.row {
  display: flex;
  flex-direction: row;
}

.rank5background {
  background: url("./shared/img/abstractbackground.webp");
  background-size: cover;
}

.column {
  display: flex;
  flex-direction: column;
}

.centeredColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space-evenly {
  justify-content: space-evenly;
}

.raise-on-hover:hover {
  transform: scale(1.005);
  box-shadow: 3px 2.9px 13.1px -9px rgba(0, 0, 0, 0.04),
    7.5px 7.4px 23.2px -9px rgba(0, 0, 0, 0.058), 15.2px 15.1px 32.4px -9px rgba(0, 0, 0, 0.072),
    31.4px 31px 45.6px -9px rgba(0, 0, 0, 0.09), 86px 85px 88px -9px rgba(0, 0, 0, 0.13);
}

::-webkit-scrollbar {
  width: 1vw;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border-radius: 100vw;
  margin-block: 0.5vh;
}

::-webkit-scrollbar-thumb {
  background: var(--light-neutral-background);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-neutral-background);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.button {
  color: var(--black);
  background-color: var(--neutral-background);
  margin: 0.3rem;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: var(--very-dark-neutral-background);
  border-width: 0.2rem;
  text-align: center;
}

.button:hover {
  background-color: var(--dark-neutral-background);
  cursor: pointer;
}

.button:active {
  color: var(--highlighted);
}

button[disabled="disabled"],
button:disabled {
  background-color: var(--very-dark-neutral-background);
}

button[disabled="disabled"],
button:disabled:hover {
  background-color: var(--very-dark-neutral-background);
  cursor: not-allowed;
}

.button-rectangular {
  width: 10rem;
  height: 3rem;
}

.button-square {
  width: 3rem;
  height: 3rem;
}

input[type="checkbox"] {
  accent-color: var(--highlighted);
}

@-webkit-keyframes backgroundGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes backgroundGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
