.genderScale {
    align-self: center;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  margin-top: -1rem;
  height: 10vh;
}

.unicorn {
  font-size: 1.8rem;
}

.playerConfig{
  justify-content: flex-start;
}

.configHeader{
  margin-bottom: 1vh;
  font-size: 1.5rem;
  align-self: center;
}

.personalQuestion{
    width: 100%;
    justify-content: start;
    align-items: baseline;
}

.traditionalGender {
  font-size: 2.5rem;
}

.ageInput {
  width: 2.3rem;
  height: 2.3rem;
  background-color: var(--dark-neutral-background);
  border-radius: var(--border-radius);
  text-align: center;
  border-style: hidden;
  padding: 0.1em;
  font-size: 26px;
}

.ageInput:active {
  background-color: var(--light-neutral-background);
}

.ageInput:focus {
  background-color: var(--neutral-background);
}

.ageInput:not(:placeholder-shown) {
 background-color: var(--highlighted);
 box-shadow: 0 0 20px var(--highlighted);
}


.playerConfigInput{
    padding: 1vw;
    
    display: flex;
    align-items: flex-start;
}

.disabledConfig{
    opacity: 0.5;
}

.ageQuestion{
    margin-right: 1vw;
}

.sliderParent{
    align-self: center;
    width: 88%;
}

.permissionQuestion{
    align-items: baseline;
    margin-top: 0;
}

.permissionChecker{
    width: 2vw;
    height: 2vw;
    align-self: center;
}


.rc-slider-handle {
  position: absolute;
  height: 3vh;
   width: 3vh;
  margin-top: -1.2vh;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 3px;
  border-color: var(--light-neutral-background);
  background-color: var(--neutral-background);
  touch-action: pan-x;

  box-shadow: unset;
}
.rc-slider-handle-dragging{
  border-color: var(--dark-neutral-background);
  box-shadow: unset;
}
.rc-slider-handle:focus {
  border-color: var(--dark-neutral-background);
  outline: none;
  box-shadow: unset;
}
.rc-slider-handle-click-focused:focus {
  border-color: var(--light-neutral-background);

  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: var(--neutral-background);
  box-shadow: unset;
}
.rc-slider-handle:active {
  border-color: var(--dark-neutral-background);
  cursor: -webkit-grabbing;
  cursor: grabbing;
  box-shadow: unset;
}