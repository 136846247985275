.resultBall {
  position: relative;
  width: 6vw;
  height: 6vw;
  border-radius: 100vw;
}

.resultBalls {
  width: 60vw;
  margin-left: 1vw;
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.692);
  border-radius: 100vh;
  transition: all 2s;
  padding-block: 2vh;
  padding-left: 0vw;
  padding-right: 0;
}

.resultContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nameHeader {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  
  margin-block: 1vh;
  margin-left: 3vw;
}

.resultSequenceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scoreMessage{
  align-self: center;
}

.animationButtons{
  justify-content: center;
}