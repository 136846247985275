.player {
  background: linear-gradient(45deg, var(--light-neutral-background), var(--dark-neutral-background));
  outline: 0.5vh solid var(--dark-neutral-background);
  border-radius: 8px;
  min-height: 8vh;
  margin-top: 1vh;
  margin-right: 1vw;
  margin-left: 1vw;
  width: 45vw;
  font-size: 7vh;
  text-indent: 1vw;
  color: black;
  background-size: 150% 150%;
  /* -webkit-animation: backgroundGradient 5s ease-in-out infinite;
  animation: backgroundGradient 5s ease-in-out infinite; */
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  
}

.tabScore {
  font-size: 14px;
  color: rgb(0, 0, 0);
  margin-right: 1vw;
}

.tabName {
  max-width: 70%;
  overflow:hidden;
}
