.questionHeader {
  height: 12vh;
  width: 70vw;
  background-color: var(--neutral-background);
  border-radius: var(--border-radius);
  margin-block: 1vh;
  /* display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px; */
}


.skipButton{
  height: 80%;
  width: 80%;
  grid-row: 1;
  font-size: 1.1rem;
  grid-column: 3;
  align-self: center;
  justify-self: center;
}

.question {
  margin: 0;
  grid-row: 1;
  justify-self: center;
  align-self: center;
 
 grid-column: 2;
  color: black;
  font-size: 1.8rem;
}
/* 
.highlighted {
  color: rgba(255, 244, 144, 0.952);
  font-size: 1.8rem;
} */
