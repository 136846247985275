.dialog-main {
  position: fixed;
  overflow: scroll;
  border-radius: var(--border-radius);
  transform: translate(-50%, -50%);
  background-color: var(--neutral-background);
  backdrop-filter: blur(20px);
  box-shadow: 20px 20px 50px black;
  opacity: 1;
  padding-left: 2%;
  padding-right: 2%;
  z-index: 1000;
  animation: slide-in 0.2s ease-out forwards;
  transition: all 0.2s ease-out;
}

@keyframes slide-in {
  from {
    position: fixed;
    top: 100%;
    left: 50%;
  }
  to {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  transition: all 0.2s ease-out;
}

.close-icon {
  font-size: 30px;
  transition: all 0.5s ease-in-out;
}

.close-icon:hover {
  scale: 1.3;
}
