.howToContainer{
    
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.howTo{
    align-items: flex-start;
    height: 100vh;
    overflow: auto;
    padding-bottom: 10vh;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    box-sizing: border-box;
}

.howToHeader{
    align-self: center;
}