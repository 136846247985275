.ratherScale {
    width: 90%;
    height: 4vh;
    align-items: center;
    justify-content: space-around;
  }

  .roundStartDialog{
      font-size: 3rem;
  }

  .introHeaders {
      
      height: 80%;
      justify-content: space-around;
      align-items: center;

  }

  .normalIntroText {
    color: black;
    font-size: 1.8rem;
    text-align: center;
  }
  
  .highlightedIntroText {
    color: rgba(255, 244, 144, 0.952);
    font-size: 1.8rem;
  }

  .centerContent{
      width: 100%;
      height: 100%;
      display: flex;
      
      flex-direction: column;
      justify-content: center;
      align-items: center;
     }