.lobbyView {
    background: url("../../shared/img/abstractbackground.webp");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  
  .lobbyInformation {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .mobileGameView {
    min-height: 100vh;            /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
  }

  .gamePage{
    height: 100vh;
    width: 100vw;
    background: url("../../shared/img/abstractbackground.webp");
    background-size: cover;
  }
  
  .playerArea {
    display: flex;
    flex-direction: column;
    width: 45vw;
    height: 80vh;
    margin-left: 2vw;
    margin-right: 1vw;
  }
  
  .gameArea {
    display: flex;
    flex-direction: column;
    width: 45vw;
    height: 80vh;
    margin-left: 1vw;
    margin-right: 2vw;
  }
  
  .lobbyButtons {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  
  .testGround {
    margin-top: 45vh;
  }
  
  .playerAndGameConfig {
    justify-content: space-around;
    align-items: stretch;
    background: var(--neutral-background);
    /* -webkit-animation: backgroundGradient 5s ease-in-out infinite;
    animation: backgroundGradient 5s ease-in-out infinite; */
    border-radius: var(--border-radius); 
  }
  