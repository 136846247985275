@media (orientation:landscape) { .frontPage{
    width: 100%;
    height: 100%;
    place-items: center;
    justify-content: flex-start;
    padding-top: 8vh;
}
}

@media (orientation:portrait) { .frontPage{
    width: 100vw;
    height: 100vh;
    place-items: center;
    justify-content: flex-start;
    padding-top: 8vh;
}
}