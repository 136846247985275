.playerList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  border-radius: var(--border-radius);
  background-color: var(--neutral-background);
}

