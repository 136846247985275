/* .faqContainer{
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
} */


@media (orientation: landscape) {
  .faq {
    align-items: flex-start;
    height: 100vh;
    overflow: auto;
    padding-bottom: 10vh;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (orientation: portrait) {
  .faq {
    align-items: flex-start;
    height: 100vh;
    overflow: auto;
    padding-bottom: 10vh;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    box-sizing: border-box;
  }
}

.faqHeader {
  align-self: center;
  color: #ffffffaf
}


.honorableMention {
  background-color: var(--neutral-background);
  border-color: var(--neutral-background);
}

@media (orientation: portrait) {
  .honorableMention {
    margin-block: 4vh;
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: 0.5fr 60vw 1fr 1fr; */
    grid-template-rows: 0.5fr 60vw 1fr 0.2fr;
    gap: 0px 0px;
    grid-template-areas:
      "."
      "."
      "."
      ".";
    justify-items: center;
    align-items: center;
    min-width: 60vw;
    border-radius: 4rem;
    border-style: solid;
  }
}

@media (orientation: landscape) {
  .honorableMention {
    margin-block: 4vh;
    display: grid;
    grid-template-columns: .5fr 1fr;
    grid-template-rows: 1fr 2fr 2fr;
    gap: 0px 0px;
    grid-template-areas:
      ". . ."
      ". . ."
      ". . .";
    justify-items: center;
    align-items: center;
    width: 50vw;
    min-height: 40vh;
    border-radius: 100vh;
    border-style: solid;
  }
}

@media (orientation: portrait) {
  .bela {
    min-height: 60vw;
    min-width: 60vw;
    grid-row: 2;
    align-self: flex-start;
    margin: 1em;
    background: url("../../shared/img/bela_1.webp");
    
    background-size: cover;
    margin-top: 0rem;
  }
}

@media (orientation: landscape) {
  .bela {
    min-height: 18vw;
    min-width: 18vw;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end:4;
    margin: 1em;
    background: url("../../shared/img/bela_1.webp");
    background-size: cover;
    align-self: flex-start;
    justify-self: center;
  }
}

.honorableMentionText {
  color: var(--highlighted);
}

@media (orientation: portrait) {
  .honorableMentionText {
    width: 80%;
    grid-row: 1;
    grid-column: 1;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0rem;
  }
}

@media (orientation: landscape) {
  .honorableMentionText {
    display: flex;
    height: 100%;
    margin-top: 2%;
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    height: 100%;
    align-self: flex-start;
  }
}

@media (orientation: portrait) {
  .thankYouMessage{
    margin-left: 10%;
    margin-right: 10%;
    justify-self: center;
    text-align: justify;
    grid-row: 3;
    grid-column: 1;
  
    font-size: 1rem;
  }
}

@media (orientation: landscape) {
  .thankYouMessage{
    justify-self: flex-start;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column: 2;
    font-size: 1.3rem;
    max-width: 90%;
  }
}