.setNameField {
  width: 25vw;
  padding-top: 2vh;
  padding-bottom: 0vh;
  padding-right: 0vw;
  padding-right: 0vw;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.nameInput {
  width: 18vw;
  font-size: 26px;
  margin-left: 0vw;
}
