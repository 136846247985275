.endPanelContainer {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.endPanel {
  justify-content: space-evenly;
  align-items: center;
  height: 30vh;
  width: 100vw;
}

.quickFeedbackContainer {
  background-color: var(--light-neutral-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25vh;
  min-width: 47vw;
  border-radius: var(--border-radius);
}

.quickFeedback {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 47vw;
  padding-bottom: 1vh;
}

.quickFeedbackText {
  font-size: x-large;
  height: 15vh;
  width: 40vw;
  margin-right: 0.5vw;
  border-radius: var(--border-radius);
}

.dataPanel {
  padding: 1vh;
  height: 60%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-neutral-background);
  backdrop-filter: blur(5px);
}

.improvementQuestion {
  font-size: 1.3rem;
}

.quickFeedbackButton {
  height: 15vh;
  width: 4vw;
  margin: 0;
}

.endPanelMessage {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plebButtons {
  justify-content: flex-end;
  grid-column: 3;
  align-items: center;
}

.dataHeader {
  font-size: large;
}

.leaveWarning {
  overflow: hidden;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.decisionButtons {
  justify-content: space-evenly;
  width: 100%;
}
.leaveWarningText {
  font-size: 1.5rem;
  width: 90%;
}

.endGameButtons {
  width: 90%;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 0px;
  justify-self: flex-end;
}

.hostButtons {
  justify-content: center;
  grid-column: 2;
}

.waitForRestart {
  grid-column: 2;
  justify-self: center;
  align-self: center;
}
