.feedbackPage {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.feedbackPanel {
  padding-inline: 2rem;
  padding-block: 0.5rem;
  width: 30rem;
  min-height: 25rem;
  justify-content: space-evenly;
  background-color: var(--neutral-background);
  border-radius: var(--border-radius);
}

@media (orientation: portrait) {
  .feedbackPanel {
    max-width: 70vw;
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
.feedbackHeader {
  font-size: x-large;
}
.feedbackText {
  height: 40%;
  font-size: x-large;
  border-radius: 0.5rem;
  border-style: hidden;
  resize: none;
}
.feedbackSignature {
  font-size: x-large;
}
.feedbackInput {
  border-radius: 0.5rem;
  font-size: large;
  height: 2rem;
  border-style: hidden;
}

.feedbackSendButton {
  align-self: flex-end;
  width: 6rem;
}

.sentMessage {
  align-items: center;
}

.feedbackSubmissionbuttons {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
